import React from 'react'
import styles from './iconHoverAnimation'
import withStyles from '@material-ui/core/styles/withStyles'

const Github = ({classes, fill}) => (
  <div className={classes.icon}>
    <svg width="100%" height="100%" fill={fill} viewBox="0 0 67 67" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.543,34.568c-0.054,0 0.592,1.367 0.61,1.367c1.41,2.516 4.128,4.08 8.713,4.514c-0.654,0.488 -1.44,1.414 -1.549,2.484c-0.823,0.523 -2.478,0.696 -3.764,0.297c-1.803,-0.559 -2.493,-4.066 -5.192,-3.566c-0.584,0.107 -0.468,0.486 0.037,0.809c0.823,0.523 1.597,1.178 2.194,2.571c0.459,1.07 1.423,2.981 4.473,2.981c1.21,0 2.058,-0.143 2.058,-0.143c0,0 0.023,2.731 0.023,3.793c0,1.225 -1.682,1.57 -1.682,2.159c0,0.233 0.557,0.255 1.004,0.255c0.884,0 2.723,-0.725 2.723,-1.998c0,-1.011 0.017,-4.41 0.017,-5.006c0,-1.3 0.709,-1.712 0.709,-1.712c0,0 0.088,6.94 -0.169,7.872c-0.302,1.094 -0.847,0.939 -0.847,1.427c0,0.726 2.214,0.179 2.948,-1.416c0.567,-1.239 0.319,-8.05 0.319,-8.05l0.605,-0.012c0,0 0.034,3.117 0.013,4.542c-0.021,1.476 -0.123,3.342 0.769,4.222c0.586,0.579 2.484,1.594 2.484,0.666c0,-0.539 -1.04,-0.982 -1.04,-2.441l0,-6.715c0.831,0 0.706,2.208 0.706,2.208l0.061,4.103c0,0 -0.184,1.494 1.645,2.119c0.645,0.223 2.025,0.283 2.09,-0.09c0.065,-0.373 -1.662,-0.928 -1.678,-2.084c-0.01,-0.707 0.032,-1.119 0.032,-4.187c0,-3.068 -0.419,-4.202 -1.88,-5.106c4.508,-0.455 7.299,-1.551 8.658,-4.486c0.106,0.003 0.555,-1.371 0.496,-1.371c0.305,-1.108 0.47,-2.419 0.502,-3.971c-0.008,-4.21 -2.058,-5.699 -2.451,-6.398c0.58,-3.187 -0.098,-4.637 -0.412,-5.135c-1.162,-0.406 -4.041,1.045 -5.615,2.067c-2.564,-0.737 -7.986,-0.666 -10.019,0.19c-3.751,-2.639 -5.736,-2.235 -5.736,-2.235c0,0 -1.283,2.259 -0.339,5.565c-1.234,1.546 -2.154,2.64 -2.154,5.539c0.001,1.634 0.197,3.096 0.638,4.372Zm12.457,29.432c-16.568,0 -30,-13.432 -30,-30c0,-16.569 13.432,-30 30,-30c16.568,0 30,13.431 30,30c0,16.568 -13.432,30 -30,30Z" />
    </svg>
  </div>
);

export default withStyles(styles)(Github)